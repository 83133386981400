<template>
  <div :id="id">
    <b-row class="mt-3">
      <b-col md="4" lg="3" sm="12">
        &nbsp;
      </b-col>
      <b-col md="4" offset-md="6" lg="3" sm="12" class="pull-right" v-if="addRoute">
        <router-link :to="{name: addRoute, params: addRouteParams}">
          <b-button block variant="success"><span class="fa fa-plus"/> {{ addText }}</b-button>
        </router-link>
      </b-col>
    </b-row>
    <br/>
    <b-row class="justify-content-center">
      <b-col md="12">
        <b-card>
          <tree
            v-if="loaded"
            :data="items"
          >
            <div slot-scope="{ node }" class="node-container">
              <div class="node-text">{{ node.text }}
                <span v-if="actions">
                  <span
                    style="margin-left: 5px"
                    v-for="action in actions"
                    v-show="!action.action.visibilityResolver || action.action.visibilityResolver(node.data)"
                    :key="action.field"
                  >
                   <router-link
                     @click.prevent=""
                     v-if="action.action.type==='route'"
                     :to="{
                       name: action.action.name ? action.action.name : action.action.nameResolver(actionParams),
                       params: action.action.paramsResolver(node.data, actionParams),
                     }"
                   >
                    <span v-bind:class="action.class">{{ action.label }}</span>
                   </router-link>
                   <span
                     style="color: #20a8d8; cursor: pointer"
                     v-if="action.action.type==='callback'"
                   >
                    <span v-bind:class="action.class" v-on:click.prevent="action.action.callbackFunction(items, node.data, actionParams, loadItems)">
                      {{ action.label}}
                    </span>
                   </span>
                  </span>
                </span>
              </div>
            </div>
          </tree>
        </b-card>
      </b-col>
    </b-row>
    <br/>
  </div>
</template>

<script>
  import Vue from 'vue';
  import LiquorTree from "liquor-tree";

  Vue.use(LiquorTree);
  import {APIService} from "../services/api";

  export default {
    name: 'TreeView',
    props: {
      id: {
        type: String,
        required: true,
      },
      path: {
        type: String,
        required: true,
        default: ''
      },
      actions: {
        type: Array,
        required: false,
      },
      extraParams: {
        type: String,
        required: false,
        default: ''
      },
      addRoute: {
        type: String,
        required: false,
        default: ''
      },
      updateRoute: {
        type: String,
        required: false,
        default: ''
      },
      addRouteParams: {
        type: Object,
        required: false,
        default: {}
      },
      addText: {
        type: String,
        required: true,
        default: ''
      },
      labelField: {
        type: String,
        required: false,
        default: 'value'
      },
      labelFieldFormatter: {
        type: Function,
        required: false
      },
    },
    data: () => {
      return {
        items: [],
        loaded: false,
      }
    },
    computed: {
      actionParams() {
        return {
          url: APIService.getBaseUrl() + this.path,
          host: APIService.getBaseUrl(),
          path: this.path,
          addRoute : this.addRoute,
          updateRoute : this.updateRoute,
          extraData: this.extraData,
        }
      }
    },
    methods: {

      editNode(node, e) {
        node.startEditing()
      },

      removeNode(node) {
        if (confirm('Are you sure?')) {
          node.remove()
        }
      },

      addChildNode(node) {
        if (node.enabled()) {
          node.append('New Node')
        }
      },

      async loadItems() {

        this.loaded = false;
        let url = `${this.host}${this.path}?sort=orderKey,ASC&sort=children.orderKey,ASC&join=children&join=parent&filter=parent.id||$isnull`;
        if (this.extraParams) {
          url += this.extraParams;
        }
        try {
          const items =  await APIService.get(url);
          this.items = items.map(item => {
            return {
              id: item.id,
              text: this.labelFieldFormatter ? this.labelFieldFormatter(item) : item[this.labelField],
              data: item,
              children: item.children.map(child => {
                return {
                  id: child.id,
                  text: this.labelFieldFormatter ? this.labelFieldFormatter(child) : child[this.labelField],
                  data: child,
                }
              })
            }
          })
        } catch (e) {
          console.error(e)
        }

        this.loaded = true;
      },
    },
    beforeMount () {
      this.host = APIService.getBaseUrl();
      this.loadItems();
    }
  }
</script>
