import {APIService} from "../../services/api";
import i18n from "@/plugins/i18n";

export const actions = [
  {
    type: 'add',
    label: '',
    class: 'fa fa-plus',
    action: {
      type: 'route',
      paramsResolver: (data, actionParams) => {
        return Object.assign({'parentId': data.id}, actionParams);
      },
      nameResolver: (actionParams) => {
        return actionParams.addRoute;
      }
    },
  },
  {
    type: 'edit',
    label: '',
    class: 'fa fa-pencil',
    action: {
      type: 'route',
      paramsResolver: (data, actionParams) => {
        return Object.assign({'id': data.id}, actionParams);
      },
      nameResolver: (actionParams) => {
        return actionParams.updateRoute;
      }
    },
  },
  {
    type: 'delete',
    label: '',
    class: 'fa fa-trash',
    action: {
      type: 'callback',
      callbackFunction: async (list, data, actionParams, refresh, eventBus) => {
        const confirmMessage = i18n.t("areYouSureYouWantDelete");
        const isDel = confirm(confirmMessage);
        if (isDel) {
          try {
            await APIService.delete(APIService.getRestFullEntityUrl(actionParams.url, data));
            eventBus.$emit('alert', {
              type: 'success',
              text: i18n.t('dataWasSuccessfullyDeleted'),
            });
          } catch (e) {
            eventBus.$emit('alert', {
              type: 'danger',
              text: i18n.t('errorOnDataDelete'),
            });
          }
          refresh();
        }
      }
    },
  }
];
